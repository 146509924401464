import { useState, useEffect } from 'react';

function getStorageValue(key: string) {
  if (!window || !localStorage) return null;
  const saved = localStorage.getItem(key);
  if (!saved) return null;
  return JSON.parse(saved);
}

export const useLocalStorage = (key: string) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key);
  });

  useEffect(() => {
    if (!window || !localStorage) return;
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
