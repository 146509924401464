import React, { useEffect } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import FixedRoom from '../messages/FixedRoom';
import OriginalFixedRoom from '../messages/OriginalFixedRoom';
import './Home.css';
import { Link } from 'react-router-dom';
import { EnumServiceType } from '../types';

const { Title, Paragraph } = Typography;

function Home() {
  useEffect(() => {
    if (window['umami']) window['umami'].track();
  }, []);
  return (
    <Row justify="space-evenly" align={{ xxl: 'middle', xs: 'top' }}>
      <Col xs={22} lg={8} xxl={6}>
        <Typography>
          <Title>
            Live chats
            <br /> should be readable
          </Title>
          <Paragraph>Chemo Chat gets rid of cancer texts so you can focus on what matters.</Paragraph>
          <Paragraph>Try actually being able to read a live chat, for once.</Paragraph>
          <Paragraph style={{ textAlign: 'center' }}>
            <Link to="/chat">
              <Button>Choose a channel</Button>
            </Link>
          </Paragraph>
        </Typography>
      </Col>
      <Col xs={0} xxl={4}>
        <OriginalFixedRoom room="example" service={EnumServiceType.Twitch} />
      </Col>
      <Col xs={0} xxl={1}>
        <DoubleRightOutlined style={{ fontSize: '4em', color: '#ccc' }} />
      </Col>
      <Col xs={22} lg={12} xxl={10}>
        <FixedRoom room="example" service={EnumServiceType.Twitch} mustLoad={false} />
      </Col>
    </Row>
  );
}

export default Home;
