import React, { createContext, useContext, useEffect, useState } from 'react';
import { io, ManagerOptions, Socket } from 'socket.io-client';
import { fromEvent, Subscription } from 'rxjs';
import { SocketConnection } from '../types';
import { NotificationContext } from './NotificationProvider';

const SocketContext = createContext<SocketConnection>({ socket: null, socketConnected: false });

function SocketProvider({ children }: any) {
  const { notification } = useContext(NotificationContext);
  const [disposes, setDisposables] = useState([]);
  const [isConnected, setConnected] = useState(false);
  const [socket, setSocket] = useState<Socket>(null);

  function listenSocket(conn) {
    if (!conn) return;
    const sub1 = fromEvent(conn, 'connect').subscribe(() => {
      setConnected(true);
      setSocket(conn);
      notification({ type: 'success', clear: true, duration: 2, message: 'Socket connected' });
    });
    const sub2 = fromEvent(conn, 'disconnect').subscribe((reason) => notification({ type: 'warning', message: `Socket disconnected: ${reason}` }));
    const sub3 = fromEvent(conn, 'connect_error').subscribe((error: any) => notification({ type: 'error', message: error.message }));
    setDisposables((d) => [...d, sub1, sub2, sub3]);
  }

  function cleanup() {
    if (socket) {
      socket.off('message');
      socket.off('connect');
      socket.off('disconnect');
      socket.off('connect_error');
      socket.offAny();
      socket.disconnect();
    }
    setConnected(false);
    setSocket(null);
    disposes.forEach((d: Subscription) => d.unsubscribe());
    setDisposables([]);
  }

  useEffect(() => {
    const url = process.env.REACT_APP_API;
    const options: Partial<ManagerOptions> = {
      transports: ['websocket'],
      reconnection: true,
      rejectUnauthorized: false,
    };
    const conn = io(url, options);
    listenSocket(conn);
    return () => {
      cleanup();
    };
  }, []);

  return <SocketContext.Provider value={{ socket, socketConnected: isConnected }}>{children}</SocketContext.Provider>;
}

export { SocketContext, SocketProvider };
