import React, { useState } from 'react';
import { Col, ConfigProvider, Image, Layout, Menu, Row } from 'antd';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import Chat from './pages/Chat';
import './App.css';
import PricingTable from './pages/PricingTable';
import Home from './pages/Home';
import About from './pages/About';
import Privacy from './pages/Privacy';
import LoginButton from './auth/Login';
import Exclusion from './pages/Exclusion';
import Settings from './pages/Settings';
import Refunds from './pages/Refunds';
import Terms from './pages/Terms';
import Extension from './pages/Extension';

const { Header, Content, Footer } = Layout;

export default function App() {
  const location = useLocation();
  const [page, setPage] = useState(location.pathname);

  const onClick = ({ key }) => {
    setPage(key);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Spin: {
            colorPrimary: '#006400',
            colorPrimaryHover: '#006400',
          },
          Button: {
            colorLink: '#006400',
            colorLinkHover: '#006400',
            colorLinkActive: '#006400',
            linkDecoration: 'underline',
            linkHoverDecoration: 'none',
            linkFocusDecoration: 'underline',
            colorPrimaryActive: '#006400',
            colorPrimary: '#006400',
            colorPrimaryHover: '#006400',
          },
          Menu: {
            itemSelectedColor: '#006400',
            horizontalItemSelectedColor: '#006400',
            itemSelectedBg: 'transparent',
          },
          Typography: {
            colorLinkActive: '#006400',
            colorLink: '#006400',
            colorLinkHover: '#006400',
            linkDecoration: 'underline',
          },
        },
      }}
    >
      <Layout className="main-app">
        <Header className="header">
          <Row>
            <Col xs={5} md={3} lg={3}>
              <Link to="/">
                <Image preview={false} src="chemo2_logo.png" className="logo" alt="Chemo Chat" width={50} />
              </Link>
            </Col>
            <Col xs={0} md={15} className="chemoMenu">
              <Menu
                mode="horizontal"
                selectedKeys={[page]}
                onClick={onClick}
                items={[
                  { label: <Link to="/">Home</Link>, key: '/' },
                  { label: <Link to="/chat">Chat</Link>, key: '/chat' },
                  { label: <Link to="/pricing">Pricing</Link>, key: '/pricing' },
                  { label: <Link to="/about">About</Link>, key: '/about' },
                  { label: <Link to="/extension">Overlay Extension</Link>, key: '/extension' },
                  { label: <Link to="/terms">Terms</Link>, key: '/terms' },
                  { label: <Link to="/privacy">Privacy</Link>, key: '/privacy' },
                ]}
              />
            </Col>
            <Col xs={17} sm={9} md={4} offset={2}>
              <LoginButton />
            </Col>
          </Row>
        </Header>
        <Content className="content">
          <Routes>
            <Route path="/" key="Home" element={<Home />} />
            <Route path="/chat" key="Chat" element={<Chat />} />
            <Route path="/extension" key="Extension" element={<Extension />} />
            <Route path="/about" key="About" element={<About />} />
            <Route path="/privacy" key="Privacy" element={<Privacy />} />
            <Route path="/terms" key="Terms" element={<Terms />} />
            <Route path="/pricing" key="Pricing" element={<PricingTable />} />
            <Route path="/exclusion" key="Exclusion" element={<Exclusion />} />
            <Route path="/refunds" key="Refunds" element={<Refunds />} />
            <Route path="/settings" key="Settings" element={<Settings />} />
          </Routes>
        </Content>
        <Footer style={{ textAlign: 'center' }}>ChemoChat ©2023-{new Date().getFullYear()}. We do not use third-party cookies.</Footer>
      </Layout>
    </ConfigProvider>
  );
}
