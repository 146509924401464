import React, { useContext, useEffect, useState } from 'react';
import Messages from './Messages';
import { PassChatSize, ChatMessage } from '../types';
import './Room.css';
import { SocketContext } from '../connections/SocketProvider';
import { ChannelContext } from '../connections/ChannelProvider';
import { fromEvent, Observable } from 'rxjs';
import { Avatar, Card, Divider, Skeleton, Space, Typography } from 'antd';
const { Meta } = Card;
const { Title } = Typography;

export default function OriginalFixedRoom({ room, service, msgsSize = 6 }: PassChatSize) {
  const { socket } = useContext(SocketContext);
  const { join, leave, info } = useContext(ChannelContext);
  const [msgs$, setMsgs] = useState<Observable<ChatMessage>>(null);

  useEffect(() => {
    if (socket) {
      join(room, service, true);
      const obs = fromEvent(socket, 'original-message');
      setMsgs(obs);
    }
    return () => {
      leave(room);
      setMsgs(null);
    };
  }, [socket]);

  return (
    <Card className="roomCard roomCard-small" aria-hidden="true">
      {info ? (
        <Meta
          avatar={<Avatar size="large" src={info.broadcaster_image} alt="" />}
          title={
            <Space align="baseline">
              <Title level={3}>{info.broadcaster_name}</Title>
              <Title level={4}>(live chat)</Title>
            </Space>
          }
        />
      ) : (
        <Skeleton active avatar={{ shape: 'circle' }} paragraph={false} />
      )}
      <Divider />
      <Messages messages$={msgs$} msgsSize={msgsSize} original={true} ariaLive={false} sizeCss="small" />
    </Card>
  );
}
