import React, { useEffect } from 'react';
import { Col, Divider, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Title, Paragraph } = Typography;

function Privacy() {
  useEffect(() => {
    if (window['umami']) window['umami'].track();
  }, []);
  return (
    <Row justify="center">
      <Col xs={21}>
        <Typography>
          <Title>Privacy</Title>
          <Paragraph>
            <strong>
              By using ChemoChat you agree to <Link to="/terms">Terms & Conditions</Link>, beyond Privacy information as described here.
            </strong>
          </Paragraph>
          <Divider />
          <Paragraph>ChemoChat doesn't sell ads, user data or any kind of profiling information.</Paragraph>
          <Paragraph>
            ChemoChat is in accordance with <a href="https://gdpr-info.eu/">GDPR law</a> and its principles. The only indentifiable information we store is your
            social login. Message processing is a <a href="https://gdpr-info.eu/art-6-gdpr/">consented usage</a> by a service provider (social network/app
            provider) which authorizes its usage to <a href="https://www.twitch.tv/p/en/legal/developer-agreement/">registered apps</a> abiding to their terms
            and conditions. However, as stated above, we do use well-known, third-party services, each with their own compliances.
          </Paragraph>
          <Paragraph>
            <strong> Supabase </strong> is used for authentication but doesn't rely on cookies.
            <strong> Umami Analytics </strong> is privately hosted and only tracks page views and our ad campaigns.
            <strong> Stripe </strong> does need to securely authorize transactions and avoid electronic fraud, and so relies on cookies. Both Stripe and our
            analytics are are considered <strong>essential, first-party usage</strong> cookies as defined by GDPR law.
          </Paragraph>
          <Paragraph>
            <strong>We do not</strong> record, keep or track chat messages, usernames, timestamps & metadata. Model training do not use any particular channel
            data, service provider, user info or input.
          </Paragraph>
          <Paragraph>
            ChemoChat stores no recollection of what was written in any chat, at any time, by one particular user. ChemoChat
            <strong> can't and doesn't claim</strong> any authorship or property over any message that passes through our systems.
          </Paragraph>
          <Paragraph>
            You can ask for your login data <Link to="/exclusion">to be excluded here</Link>.
          </Paragraph>
          <Paragraph>
            We <strong>consciously</strong> made the design choice of not allowing chat input through our app; we want to avoid streaming services dealing with
            another source of possible spam, and not to compete with native, first-party apps in that regard.
          </Paragraph>
        </Typography>
      </Col>
    </Row>
  );
}

export default Privacy;
