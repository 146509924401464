import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import Room from '../messages/Room';
import ChatCards from '../messages/ChatCard';

function Chat() {
  useEffect(() => {
    if (window['umami']) window['umami'].track();
  }, []);
  return (
    <Row justify="center">
      <Col xs={{ span: 22, order: 2 }} lg={{ span: 8, order: 1 }} xl={{ span: 6, order: 1, offset: 2 }}>
        <ChatCards />
      </Col>
      <Col offset={1} xs={{ span: 22, order: 1 }} lg={{ span: 12, order: 2 }} xl={{ span: 8, order: 2 }}>
        <Room msgsSize={11} />
      </Col>
    </Row>
  );
}

export default Chat;
