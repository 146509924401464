import { useContext } from 'react';
import { UserContext } from '../auth/AuthProvider';

export default function useApi() {
  const { session } = useContext(UserContext);
  const req = async (url: string, data = null) => {
    const uri = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_API}${url}`;
    const token = session ? session.access_token : '';
    const options: any = { method: data ? 'post' : 'get', headers: { authorization: 'Bearer ' + token } };
    if (data) options.body = JSON.stringify(data);
    const result = await fetch(uri, options);
    return await result.json();
  };
  const getToken = () => {
    return session ? session.access_token : '';
  };

  return { req, getToken };
}
