import React, { useContext, useState } from 'react';
import { Avatar, Card, Divider, Drawer, Space, Typography } from 'antd';
import Messages from './Messages';
import RoomInput from './RoomInput';
import Stats from './Stats';
import './Room.css';
import ProgressCancer from '../progress/ProgressCancer';
import ProgressSentiment from '../progress/ProgressSentiment';
import { FilterFilled, FilterOutlined, LogoutOutlined } from '@ant-design/icons';
import { ChannelContext } from '../connections/ChannelProvider';
import Chart from './Chart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { castServiceName, EnumServiceType } from '../types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTiktok, faTwitch, faYoutube } from '@fortawesome/free-brands-svg-icons';

const { Title } = Typography;
const { Meta } = Card;

function Room({ msgsSize }) {
  const [deslang, setDeslang] = useState(false);
  const [openChart, setChart] = useState(false);
  const { channel, leave, info, messages$, channelConnected } = useContext(ChannelContext);

  const handleChart = () => {
    if (channelConnected) setChart(true);
  };

  const trackDeslang = (val: boolean) => {
    if (window['umami']) window['umami'].track('deslang-button', { name: val ? 'deslang' : 'normal' });
    setDeslang(val);
  };

  const castService = (service: EnumServiceType): IconProp => {
    if (service === EnumServiceType.Twitch) return faTwitch as IconProp;
    else if (service === EnumServiceType.Tiktok) return faTiktok as IconProp;
    else if (service === EnumServiceType.YouTube) return faYoutube as IconProp;
    else return null;
  };

  return (
    <Space>
      <Card className="roomCard">
        {info ? (
          <>
            <Meta
              avatar={<Avatar size="large" src={info.broadcaster_image} alt="" />}
              title={
                <Typography>
                  <Title level={2}>{info.broadcaster_name}</Title>
                </Typography>
              }
            />
            <Typography>
              <Title level={3}>{info.game_name}</Title>
            </Typography>
            <Space className="leave" size="large">
              <FontAwesomeIcon icon={castService(info.service)} title={castServiceName(info.service)} />
              {deslang && <FilterFilled onClick={() => trackDeslang(false)} alt="de-slang" title="translate slangs on/off" />}
              {!deslang && <FilterOutlined onClick={() => trackDeslang(true)} alt="de-slang" title="translate slangs on/off" />}
              <LogoutOutlined onClick={() => leave()} alt="leave" title="Leave this channel" />
            </Space>
          </>
        ) : (
          <Meta avatar={null} title="Select any channel" />
        )}
        <Divider />
        <Stats />
        {!channel && <RoomInput />}
        <Divider />
        <Messages messages$={messages$} msgsSize={msgsSize} deslang={deslang} />
      </Card>
      <ProgressCancer />
      <div onClick={handleChart}>
        <ProgressSentiment />
      </div>
      <Drawer title="History" placement="right" closable={true} onClose={() => setChart(false)} open={openChart} size="large">
        <Chart />
      </Drawer>
    </Space>
  );
}

export default Room;
