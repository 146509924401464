import React, { useContext, useEffect, useState } from 'react';
import { CartesianGrid, LineChart, XAxis, Tooltip, Line, Legend, ResponsiveContainer, YAxis } from 'recharts';
import { ChannelContext } from '../connections/ChannelProvider';
import { Subscription } from 'rxjs';

export default function Chart() {
  const [data, setData] = useState([]);
  const { chart$ } = useContext(ChannelContext);
  const [sub, setSub] = useState<Subscription>(null);

  useEffect(() => {
    if (sub) sub.unsubscribe();
    if (chart$) {
      setSub(
        chart$.subscribe((update) => {
          setData((prev) => {
            if (prev.length > 50) return [...prev.slice(1, 50), update];
            else return [...prev, update];
          });
        }),
      );
    }
    return () => {
      if (sub) sub.unsubscribe();
      setData([]);
      setSub(null);
    };
  }, [chart$]);

  return (
    <>
      <ResponsiveContainer width="100%" height="30%">
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, 'dataMax']} unit="/min" />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="speed" stroke="#0000cc" isAnimationActive={false} />
        </LineChart>
      </ResponsiveContainer>
      <ResponsiveContainer width="100%" height="70%">
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, 'dataMax']} unit="%" />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="laugh" stroke="#FF8800" isAnimationActive={false} />
          <Line type="monotone" dataKey="praise" stroke="#7A7600" isAnimationActive={false} />
          <Line type="monotone" dataKey="thirsty" stroke="#D3178E" isAnimationActive={false} />
          <Line type="monotone" dataKey="sad" stroke="#716895" isAnimationActive={false} />
          <Line type="monotone" dataKey="bots" stroke="#3B6A7B" isAnimationActive={false} />
          <Line type="monotone" dataKey="beggar" stroke="#854700" isAnimationActive={false} />
          <Line type="monotone" dataKey="anger" stroke="#B30000" isAnimationActive={false} />
          <Line type="monotone" dataKey="joy" stroke="#FFC105" isAnimationActive={false} />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}
