import React, { createContext, useContext, useEffect, useState } from 'react';
import { castServiceName, ChannelConnection, ChannelEvents, ChatMessage, EnumServiceType } from '../types';
import { SocketContext } from './SocketProvider';
import { BehaviorSubject, filter, fromEvent, map, Observable, ReplaySubject } from 'rxjs';
import { NotificationContext } from './NotificationProvider';
import useApi from './Api';

const ChannelContext = createContext<ChannelConnection>({
  channel: null,
  service: null,
  events$: null,
  messages$: null,
  chart$: null,
  info: null,
  channelConnected: false,
  join: () => ({}),
  leave: () => ({}),
});

function ChannelProvider({ children }: any) {
  const { req, getToken } = useApi();
  const { socket, socketConnected } = useContext(SocketContext);
  const { notification } = useContext(NotificationContext);
  const [channelConnected, setConnected] = useState(false);
  const [channel, setChannel] = useState(null);
  const [service, setService] = useState<EnumServiceType>(null);
  const [info, setInfo] = useState(null);
  const [event, setEvent] = useState<Observable<ChannelEvents>>(null);
  const [chart, setChart] = useState<ReplaySubject<any>>(null);
  const [messages, setMessages] = useState<Observable<ChatMessage>>(null);

  let connecting = false;

  function leave(room = null) {
    if (socket) socket.emit('leaveRoom', channel || room);
    if (window['umami']) window['umami'].track('channel', { name: 'leave', service: castServiceName(service), channel: room });
    connecting = false;
    setInfo(null);
    setEvent(null);
    setChannel(null);
    setConnected(false);
    setMessages(null);
    setChart(null);
    setService(null);
  }

  function listenMessages(room: string, service: EnumServiceType, token: string, original: boolean) {
    setMessages(null);
    socket.emit('joinRoom', { room, service, token, original });
    const obs = fromEvent(socket, 'message').pipe(filter((msg) => msg.room === room));
    setMessages(obs);
  }

  function listenEvents(room: string) {
    setEvent(null);
    const obs = fromEvent(socket, 'channelevents').pipe(filter((msg) => msg.room === room));
    setEvent(obs);
    // save a little history for Chart
    const replay = new ReplaySubject(30);
    obs
      .pipe(
        map((event) => {
          const now = new Date();
          const now_txt = `T${now.toLocaleTimeString('it-IT')}`;
          return { ...event, name: now_txt, speed: Math.round(event.speed * 60) };
        }),
      )
      .subscribe(replay);
    setChart(replay);
  }

  function handleChannelInfo(room: string, service: EnumServiceType, json: any, token: string, original: boolean) {
    setConnected(true);
    setInfo(json);
    setChannel(room);
    setService(service);
    setChart(null);
    if (!json.broadcaster_name || json.broadcaster_name === '') {
      setMessages(
        new BehaviorSubject<ChatMessage>({
          badges: [],
          deslang: '',
          highlight: false,
          id: '',
          isMod: false,
          room: '',
          roomId: '',
          time: '',
          userId: '',
          color: '#000',
          name: 'ChemoChat',
          text: 'This channel does not exist.',
        }),
      );
    } else if (!json.is_live) {
      setMessages(
        new BehaviorSubject<ChatMessage>({
          badges: [],
          deslang: '',
          highlight: false,
          id: '',
          isMod: false,
          room: '',
          roomId: '',
          time: '',
          userId: '',
          color: '#000',
          name: 'ChemoChat',
          text: 'This channel is not currently live streaming',
        }),
      );
    } else {
      if (!socket) throw new Error('socket not connected after channel info arrived');
      if (window['umami']) window['umami'].track('channel', { name: 'join', service: castServiceName(service), channel: room });
      listenMessages(room, service, token, original);
      listenEvents(room);
    }
  }

  async function join(joinRoom: string, service: EnumServiceType, original = false) {
    if (!socket || connecting || !joinRoom || joinRoom.trim() === '') return;
    connecting = true;
    const room = joinRoom
      .trim()
      .replaceAll(/[^0-9a-zA-Z_]/gu, '') // ASCII only
      .toLowerCase();
    if (room === channel) return;
    leave(); // clear old room first

    try {
      const res = await req(`/api/info/${service}/${room}`);
      if (!res.success) {
        connecting = false;
        setConnected(false);
        if (res.statusCode === 401) {
          notification({ type: 'info', message: 'Log in to access channels' });
        } else {
          notification({ type: 'error', message: res.message });
        }
      } else {
        connecting = false;
        const json = res.data;
        return handleChannelInfo(room, service, json, getToken(), original);
      }
    } catch (error) {
      connecting = false;
      setConnected(false);
      notification({ type: 'error', message: error.message });
    }
  }

  useEffect(() => {
    return () => {
      leave();
    };
  }, []);

  useEffect(() => {
    setConnected(socketConnected && channel && !!info);
  }, [socketConnected, channel]);

  return (
    <ChannelContext.Provider value={{ channel, service, info, events$: event, channelConnected, join, leave, messages$: messages, chart$: chart }}>
      {children}
    </ChannelContext.Provider>
  );
}

export { ChannelContext, ChannelProvider };
