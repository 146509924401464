import React, { useState, useEffect, createContext } from 'react';
import { UserConnection } from '../types';
import { Provider } from '@supabase/auth-js/src/lib/types';
import supabaseFactory from './Supabase';
import { Session } from '@supabase/supabase-js';

const UserContext = createContext<UserConnection>({
  session: null,
  logout: () => ({}),
  loginSocial: () => ({}),
  loginEmail: () => ({}),
  loginLocal: () => ({}),
});

function AuthProvider({ children }: any) {
  const supabase = supabaseFactory();
  const [session, setSession] = useState<Session>(null);
  const [, setData] = useState(null);
  const [, setError] = useState(null);

  const logout = async () => {
    setData(null);
    setError(null);
    if (window['umami']) window['umami'].track('logout');
    return supabase.auth.signOut();
  };

  const loginEmail = async () => ({});

  const loginLocal = () => {
    return setSession({
      access_token: process.env.REACT_APP_TEST_JWT || '',
      expires_in: 0,
      refresh_token: '',
      token_type: '',
      user: {
        id: '1',
        app_metadata: {},
        user_metadata: {
          avatar_url: 'https://static-cdn.jtvnw.net/jtv_user_pictures/fmalk-profile_image-7aec8c94f17669f7-300x300.png',
          nickname: 'Fernando Local',
          plan: 'Champion',
        },
        aud: 'chemo',
        created_at: '2024-01-01',
        email: 'fernando@example.com',
      },
    });
  };

  const loginSocial = async (provider: Provider) => {
    if (window['umami']) window['umami'].track('login-social', { name: provider });
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider,
    });
    setData(data);
    setError(error);
  };

  useEffect(() => {
    // supabase.auth
    //   .getSession()
    //   .then(({ data: { session } }) => {
    //     setSession(session);
    //   })
    //   .catch((e) => setError(e));
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
    return () => subscription.unsubscribe();
  }, []);

  return <UserContext.Provider value={{ session, logout, loginSocial, loginEmail, loginLocal }}>{children}</UserContext.Provider>;
}

export { UserContext, AuthProvider };
