import React, { useContext, useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import { ChannelEvents, EnumServiceType } from '../types';
import { Col, Row, Skeleton, Statistic } from 'antd';
import './Stats.css';
import { ChannelContext } from '../connections/ChannelProvider';
import { faAngry, faFaceGrinTongueWink, faFaceSmile, faGrinSquintTears, faHandHolding, faRobot, faSadCry, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AriaLiveContext } from '../connections/AriaLiveProvider';

function Stats({ fixed4 = false }) {
  const { channel, service, events$ } = useContext(ChannelContext);
  const { ariaFlipRead, ariaDeltaRead } = useContext(AriaLiveContext);
  const [info, setInfo] = useState<ChannelEvents>(new ChannelEvents());
  const [sub, setSub] = useState<Subscription>(null);

  const [cheers, setCheers] = useState({ count: 0, dot: false, timer: null });
  const [subs, setSubs] = useState({ count: 0, dot: false, timer: null });
  const [topFeel, setTopFeel] = useState({ name: '', count: 0, dot: false, timer: null });
  const [topIcon, setTopIcon] = useState({ title: '', icon: null });
  const [gifts, setGifts] = useState({ count: 0, dot: false, timer: null });
  const [raids] = useState({ count: 0, dot: false, timer: null });
  const [tiers] = useState({ count: 0, dot: false, timer: null });
  const [speedColor, setSpeedColor] = useState('black');
  const [loading, setLoading] = useState(true);

  const icons = [
    {
      title: 'bots',
      icon: <FontAwesomeIcon icon={faRobot} size={'xl'} color={'#3B6A7B'} title={'bots'} />,
    },
    {
      title: 'thirsty',
      icon: <FontAwesomeIcon icon={faFaceGrinTongueWink} size={'xl'} color={'#D3178E'} title={'thirsty'} />,
    },
    {
      title: 'praise',
      icon: <FontAwesomeIcon icon={faTrophy} size={'xl'} color={'#7A7600'} title={'praise'} />,
    },
    {
      title: 'joy',
      icon: <FontAwesomeIcon icon={faFaceSmile} size={'xl'} color={'#FFC105'} title={'joy'} />,
    },
    {
      title: 'anger',
      icon: <FontAwesomeIcon icon={faAngry} size={'xl'} color={'#B30000'} title={'anger'} />,
    },
    {
      title: 'sad',
      icon: <FontAwesomeIcon icon={faSadCry} size={'xl'} color={'#716895'} title={'sadness'} />,
    },
    {
      title: 'beggar',
      icon: <FontAwesomeIcon icon={faHandHolding} size={'xl'} color={'#854700'} title={'beggars'} />,
    },
    {
      title: 'laugh',
      icon: <FontAwesomeIcon icon={faGrinSquintTears} size={'xl'} color={'#FF8800'} title={'laughter'} />,
    },
  ];

  const formatCount = (count: number): string => {
    if (count < 1000) return '' + count;
    return '' + Math.round((count * 10) / 1000) / 10;
  };

  function cleanStat(setCallback: React.Dispatch<React.SetStateAction<any>>) {
    setCallback({ count: 0, dot: false, timer: null });
  }

  function listenStat(counter: number, setCallback: React.Dispatch<React.SetStateAction<any>>, name = '') {
    setCallback((prev) => {
      if (prev.count === counter) return prev;
      if (prev.timer) clearTimeout(prev.timer);
      return {
        name,
        count: counter,
      };
    });
  }

  function listenFeels(event: any) {
    let max = 0;
    let feel = '';
    ['praise', 'joy', 'thirsty', 'laugh', 'sad', 'bots', 'beggar', 'anger'].forEach((f) => {
      if (event[f] > max) {
        max = event[f];
        feel = f;
      }
    });
    listenStat(max, setTopFeel, feel);
    if (max >= 10) ariaFlipRead(feel, max, `chat is ${Math.round(max)}% ${feel}`);
    setTopIcon(icons.find((x) => x.title === feel));
  }

  useEffect(() => {
    if (sub) sub.unsubscribe();
    if (events$) {
      setSub(
        events$.subscribe((event) => {
          setLoading(false);
          setInfo((prev) => {
            if (prev.speed * 1.2 < event.speed) setSpeedColor('#3f8600');
            else if (prev.speed * 0.8 > prev.speed) setSpeedColor('#cf1322');
            else setSpeedColor('black');
            return event;
          });
          ariaDeltaRead(event.cancer, `cancer at ${event.cancer}%`);
          listenFeels(event);
          listenStat(event.cheers, setCheers);
          listenStat(event.subs, setSubs);
          listenStat(event.gifts, setGifts);
        }),
      );
    }
    return () => {
      if (sub) sub.unsubscribe();
      setInfo(new ChannelEvents());
      setSub(null);
      setLoading(true);
      [setCheers, setSubs, setTopFeel].forEach((s) => cleanStat(s));
    };
  }, [channel, events$]);

  return !loading ? (
    <Row justify="start" gutter={10} className="stats">
      {info.cancer > 0 &&
        (fixed4 ? (
          <Col xs={5}>
            <Statistic title="cancer" value={info.cancer} suffix="%" />
          </Col>
        ) : (
          <Col xs={0} sm={6} lg={5}>
            <Statistic title="cancer" value={info.cancer} suffix="%" />
          </Col>
        ))}
      {info.speed > 0 &&
        (fixed4 ? (
          <Col xs={6}>
            <Statistic
              className="speed"
              title="messages"
              value={formatCount(Math.round(info.speed * 60))}
              suffix={Math.round(info.speed * 60) > 1000 ? 'k/m' : '/m'}
              valueStyle={{ color: speedColor }}
            />
          </Col>
        ) : (
          <Col xs={10} sm={6}>
            <Statistic
              className="speed"
              title="messages"
              value={formatCount(Math.round(info.speed * 60))}
              suffix={Math.round(info.speed * 60) > 1000 ? 'k/m' : '/m'}
              valueStyle={{ color: speedColor }}
            />
          </Col>
        ))}
      {info.drops >= 1 && !fixed4 && (
        <Col xs={0} sm={6} xxl={4}>
          <Statistic title="drowned" value={info.drops} suffix="%" />
        </Col>
      )}
      {service === EnumServiceType.Twitch &&
        subs.count > 0 &&
        (fixed4 ? (
          <Col xs={3}>
            <Statistic className="subs" title="subs" value={formatCount(subs.count) + (subs.count > 1000 ? 'k' : '')} />
          </Col>
        ) : (
          <Col xs={0} xxl={3}>
            <Statistic className="subs" title="subs" value={formatCount(subs.count) + (subs.count > 1000 ? 'k' : '')} />
          </Col>
        ))}
      {service === EnumServiceType.Tiktok &&
        gifts.count > 0 &&
        (fixed4 ? (
          <Col xs={3}>
            <Statistic className="gifts" title="gifts" value={formatCount(gifts.count) + (gifts.count > 1000 ? 'k' : '')} />
          </Col>
        ) : (
          <Col xs={0} xxl={3}>
            <Statistic className="gifts" title="gifts" value={formatCount(gifts.count) + (gifts.count > 1000 ? 'k' : '')} />
          </Col>
        ))}
      {cheers.count > 0 && !fixed4 && (
        <Col xs={0} xxl={3}>
          <Statistic title="cheers" value={cheers.count} />
        </Col>
      )}
      {raids.count > 0 && !fixed4 && (
        <Col xs={0} xxl={3}>
          <Statistic title="raids" value={raids.count} />
        </Col>
      )}
      {tiers.count > 0 && !fixed4 && (
        <Col xs={0} xxl={3}>
          <Statistic title="tiers" value={tiers.count} />
        </Col>
      )}
      {topFeel.count > 9 &&
        topIcon &&
        (fixed4 ? (
          <Col xs={6}>
            <Statistic className="feels" title="chat is" value={`${Math.round(topFeel.count)}%`} suffix={topIcon.icon} style={{ width: 'max-content' }} />
          </Col>
        ) : (
          <Col xs={12} sm={6}>
            <Statistic className="feels" title="chat is" value={`${Math.round(topFeel.count)}%`} suffix={topIcon.icon} style={{ width: 'max-content' }} />
          </Col>
        ))}
    </Row>
  ) : channel && channel !== '' ? (
    <Skeleton active paragraph={false} />
  ) : (
    <></>
  );
}

export default Stats;
