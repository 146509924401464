import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { SocketProvider } from './connections/SocketProvider';
import { ChannelProvider } from './connections/ChannelProvider';
import { NotificationProvider } from './connections/NotificationProvider';
import Plugin from './pages/Plugin';
import './index.css';
import { AuthProvider } from './auth/AuthProvider';
import { AriaLiveProvider } from './connections/AriaLiveProvider';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <NotificationProvider>
    <AuthProvider>
      <SocketProvider>
        <AriaLiveProvider>
          <ChannelProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/overlay" key="Overlay" element={<Plugin />} />
                <Route path="*" element={<App />} />
              </Routes>
            </BrowserRouter>
          </ChannelProvider>
        </AriaLiveProvider>
      </SocketProvider>
    </AuthProvider>
  </NotificationProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
