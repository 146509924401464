import React, { useEffect } from 'react';
import { Col, Divider, Row, Typography } from 'antd';

const { Title, Paragraph } = Typography;

function Exclusion() {
  useEffect(() => {
    if (window['umami']) window['umami'].track();
  }, []);
  return (
    <Row justify="center">
      <Col xs={21}>
        <Typography>
          <Title>User Data Exclusion</Title>
          <Paragraph>
            In accordance with <a href="https://gdpr-info.eu/">GDPR laws</a>, any user previously registered with us can request its own removal from our
            system.
          </Paragraph>
          <Paragraph>
            This will include: removal from our Auth database (and any social links associated), and removal from Stripe customer base.
            <strong> Any subscription will be immediately canceled. </strong> There's no other associated user info left behind.
          </Paragraph>
          <Paragraph>A subscription with us can be cancelled at any time, without the need to remove a user completely from our records.</Paragraph>
          <Divider />
          <Title level={2}>Instructions</Title>
          <Paragraph>
            A logged customer can cancel any subscription either with Stripe directly or by clicking on
            <strong> Login Menu &gt; Settings &gt; Manage Subscription</strong>.
          </Paragraph>
          <Paragraph>
            A logged user can ask for its own exclusion by clicking on <strong>Login Menu &gt; Settings &gt; Delete User</strong>.
          </Paragraph>
        </Typography>
      </Col>
    </Row>
  );
}

export default Exclusion;
