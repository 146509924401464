import React, { createContext, useEffect, useState } from 'react';
import { LiveAnnouncer, LiveMessage } from 'react-aria-live';
import { useDebounce } from 'use-debounce';
import { AriaConnection } from '../types';

const AriaLiveContext = createContext<AriaConnection>(null);

function AriaLiveProvider({ children }: any) {
  const [read, ariaRead] = useState<string>('');
  const [text] = useDebounce(read, 500);
  const [knownState, setKnownState] = useState<string>('');
  const [knownNumber, setKnownNumber] = useState<number>(0);

  const ariaFlipRead = (state: string, current: number, read: string) => {
    if (state === knownState) ariaDeltaRead(current, read);
    else {
      console.log('flip', knownState, ':', state);
      setKnownState(state);
      setKnownNumber(current);
      ariaRead(read);
    }
  };

  const ariaDeltaRead = (current: number, read: string) => {
    if (knownNumber < 1 || current >= knownNumber * 1.2 || current <= knownNumber * 0.8) {
      console.log('delta', knownNumber, ':', current);
      setKnownNumber(current);
      ariaRead(read);
    }
  };

  return (
    <AriaLiveContext.Provider value={{ ariaRead, ariaFlipRead, ariaDeltaRead }}>
      <LiveAnnouncer>
        <LiveMessage message={text} aria-live="polite" clearOnUnmount="true" />
        {children}
      </LiveAnnouncer>
    </AriaLiveContext.Provider>
  );
}

export { AriaLiveContext, AriaLiveProvider };
