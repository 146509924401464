import React, { createContext, useEffect } from 'react';
import { notification } from 'antd';
import { NotificationType } from '../types';

const NotificationContext = createContext(null);

function NotificationProvider({ children }: any) {
  const [notes, contextHolder] = notification.useNotification();
  const maxUse = new Map();

  useEffect(() => {
    notification.config({
      maxCount: 3,
    });
    return () => {
      maxUse.clear();
    };
  }, []);

  const openNotification = ({ message, type = 'open', duration = 3, clear = false }: NotificationType) => {
    if (clear) notes.destroy();
    notes[type]({
      message,
      duration,
      placement: 'bottomRight',
    });
  };

  return (
    <>
      {contextHolder}
      <NotificationContext.Provider value={{ notification: openNotification }}>{children}</NotificationContext.Provider>
    </>
  );
}

export { NotificationContext, NotificationProvider };
