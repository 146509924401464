import React, { useEffect } from 'react';
import { Col, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Title, Paragraph } = Typography;

function Extension() {
  useEffect(() => {
    if (window['umami']) window['umami'].track();
  }, []);
  return (
    <Row justify="center">
      <Col xs={21}>
        <Typography>
          <Title>Overlay Extension</Title>
          <Paragraph>
            If you use a software like <strong>OBS Studio</strong> or <strong>Streamlabs Desktop</strong> you can tailor ChemoChat as an overlay.
          </Paragraph>
          <Paragraph>
            This is intended to subscribers of the "Champion" plan, but during Open Beta it is available under a free of charge&nbsp;
            <Link to="/pricing">Subscription</Link>.
          </Paragraph>
          <Paragraph>
            Recording softwares usually allow you to set an URL as an origin source of an overlay. You have to point to
            https://chemo.chat/plugin?key=your_own_overlay_key
          </Paragraph>
          <Paragraph>
            The provided key identifies you as a User and which Service/Channel you want to consume. Go to&nbsp;
            <strong>Login Menu &gt; Settings &gt; Generate Key</strong> to create your personal Key. This option is only available to subscribers of the
            "Champion" plan.
          </Paragraph>
          <Paragraph>You can customize the CSS using these suggested tags & classes:</Paragraph>
          <Paragraph code={true}>
            body (tranparent background by default)
            <br />
            .plugin (encompass the container)
            <br />
            .broadcasterInfo
            <br />
            .broadcasterName
            <br />
            .messages (encompass the messages container)
            <br />
            .message (each individual message)
          </Paragraph>
          <Paragraph>In the future ChemoChat will be available as an official Twitch Extension. The process is under review.</Paragraph>
        </Typography>
      </Col>
    </Row>
  );
}

export default Extension;
