// @ts-nocheck
import React, { useEffect } from 'react';
import { Col, Row, Typography, List } from 'antd';

const { Title, Paragraph } = Typography;

const cards = [
  { brand: 'Mastercard US', number: '5555555555554444' },
  { brand: 'Available Balance US', number: '4000000000000077' },
  { brand: 'Visa Brazil', number: '4000000760000002' },
  { brand: 'Visa Germany (3D Secure)', number: '4000002760000016' },
];

function PricingTable() {
  useEffect(() => {
    if (window['umami']) window['umami'].track();
  }, []);
  return (
    <>
      <stripe-pricing-table
        pricing-table-id="prctbl_1Pcz4nHqT0707NtmtDiXp8Aq"
        publishable-key="pk_test_51MspM3HqT0707Ntmp53WEaBzOeHU00UYuBNh6q1ACxz0PeGiZShvPOBE360Drg7aZPyRANhWi4WoQOmP58W62Mxx006UZHrviR"
      />
      <Row justify="center">
        <Col xs={21}>
          <Typography>
            <Title level={2}>Open Beta</Title>
            <Paragraph>
              We are at Open Beta testing so you are <strong>encouraged</strong> to submit fake payment data to create a subscription for you. This is&nbsp;
              <strong>totally fine for Stripe</strong> as we're using their test sandbox environment to make sure we're properly using their API.
            </Paragraph>
            <Paragraph>Feel free to use one of these fake credit cards below to start a test subscription with us:</Paragraph>
            <List
              grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 4,
                lg: 4,
                xl: 4,
                xxl: 4,
              }}
              dataSource={cards}
              renderItem={(c, i) => (
                <List.Item key={i}>
                  <List.Item.Meta title={c.number} description={c.brand} />
                </List.Item>
              )}
            />
          </Typography>
        </Col>
      </Row>
    </>
  );
}

export default PricingTable;
