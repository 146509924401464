import React, { useContext } from 'react';
import { Menu, Avatar } from 'antd';
import './Login.css';
import { ChannelContext } from '../connections/ChannelProvider';
import { UserContext } from './AuthProvider';
import { useNavigate } from 'react-router-dom';

function LoginButton() {
  const { session, logout, loginSocial, loginLocal } = useContext(UserContext);
  const navigate = useNavigate();
  const { leave } = useContext(ChannelContext);

  function onClick({ key }) {
    switch (key) {
      case 'Logout':
        leave();
        logout();
        break;
      case 'Settings':
        return navigate('/settings');
    }
  }

  function onLoggingIn({ key }) {
    return loginSocial(key);
    // return loginLocal();
  }

  return !session ? (
    <Menu
      mode="horizontal"
      onClick={onLoggingIn}
      items={[
        {
          label: 'Log in',
          key: 'login',
          children: [
            { label: 'Twitch', key: 'twitch' },
            { label: 'Github', key: 'github' },
            { label: 'Facebook', key: 'facebook' },
          ],
        },
      ]}
    />
  ) : (
    <Menu
      mode="horizontal"
      className="settings"
      onClick={onClick}
      selectedKeys={[]}
      items={[
        {
          label: session.user.user_metadata.nickname,
          icon: <Avatar size={48} src={session.user.user_metadata.avatar_url} alt="" />,
          key: 'logged',
          children: [
            { label: 'Settings', key: 'Settings' },
            { label: 'Log out', key: 'Logout' },
          ],
        },
      ]}
    />
  );
}

export default LoginButton;
