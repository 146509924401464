import FixedRoom from '../messages/FixedRoom';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { NotificationContext } from '../connections/NotificationProvider';
import { EnumServiceType } from '../types';
import useApi from '../connections/Api';

const { Content } = Layout;

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Plugin() {
  const query = useQuery();
  const { req } = useApi();
  const { notification } = useContext(NotificationContext);
  const [room, setRoom] = useState(null);
  const [service, setService] = useState<EnumServiceType>(null);

  useEffect(() => {
    if (window['umami']) window['umami'].track();
    Promise.resolve()
      .then(() => {
        const input = query.get('key');
        if (!input || input.trim() === '') return Promise.reject(new Error('Missing ?key= in query params'));
        else return req('/api/overlay-key?key=' + input);
      })
      .then((res) => {
        if (!res.success) return Promise.reject(new Error('Invalid ?key= in query params'));
        else {
          setRoom(res.data.message);
          setService(res.data.service);
        }
      })
      .catch((e) => {
        const txt = e?.message ? e.message : e ? e : 'unknown error';
        notification({ type: 'error', message: txt });
        setRoom(null);
        setService(null);
      });
  }, []);

  return (
    <Layout className="plugin" style={{}}>
      <Content>
        <FixedRoom room={room} service={service} msgsSize={12} mustLoad={true} fixed4={true} />
      </Content>
    </Layout>
  );
}
