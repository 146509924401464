import './VerticalProgress.css';
import { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ProgressProps = {
  progress: ProgressInfo[];
};

type ProgressInfo = {
  color: string;
  dark: boolean;
  title: string;
  pct: number;
  icon?: ReactElement<typeof FontAwesomeIcon>;
  bubbles: boolean;
  waves: string | false;
};

function Waves({ rgb }: { rgb: string }) {
  return (
    <div style={{ marginTop: '-15px' }} aria-hidden="true">
      <svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
        <defs>
          <path d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g className="parallax">
          <use xlinkHref="#gentle-wave" x="48" y="0" fill={`rgba(${rgb},0.9)`} />
          <use xlinkHref="#gentle-wave" x="48" y="3" fill={`rgba(${rgb},0.7)`} />
          <use xlinkHref="#gentle-wave" x="48" y="5" fill={`rgba(${rgb},0.5)`} />
        </g>
      </svg>
    </div>
  );
}

function Bubbles() {
  return (
    <>
      <div className="bubble small" aria-hidden="true"></div>
      <div className="bubble s-medium" aria-hidden="true"></div>
      <div className="bubble medium" aria-hidden="true"></div>
      <div className="bubble large" aria-hidden="true"></div>
      <div className="bubble small-l" aria-hidden="true"></div>
      <div className="bubble small" aria-hidden="true"></div>
      <div className="bubble s-medium" aria-hidden="true"></div>
      <div className="bubble large" aria-hidden="true"></div>
      <div className="bubble medium" aria-hidden="true"></div>
    </>
  );
}

export function adjustBounce(prev, event, index, cb) {
  if (prev[index] > event[index] + 2) cb(-1);
  else if (prev[index] + 2 < event[index]) cb(1);
  else cb(0);
}

export default function VerticalProgress({ progress }: ProgressProps) {
  return (
    <div className="progress progress-bar-vertical">
      {progress.map((p, idx) => {
        const height = `${Math.round(p.pct)}%`;
        const showIcon = p.pct > 1;
        const showWaves = p.pct > 0;
        const showPct = p.pct > 4 ? height : '';
        const opacity = 1; //p.pct > 4 ? 1 : p.pct / 10;
        const classes = ['progress-bar'];
        if (!p.dark) classes.push('white');
        else classes.push('black');
        return (
          <div
            key={idx}
            className={classes.join(' ')}
            role="progressbar"
            aria-valuemin={0}
            aria-valuemax={100}
            aria-valuenow={Math.max(0, p.pct)}
            style={{ backgroundColor: p.color, height: `${Math.max(0, p.pct)}%`, opacity }}
            title={`${p.title} ${height}`}
          >
            {showWaves && p.waves && <Waves rgb={p.waves} />}
            {showIcon && p.icon}
            {showPct}
            {showIcon && p.bubbles && <Bubbles />}
          </div>
        );
      })}
    </div>
  );
}
