import React, { useContext, useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import { useLocation } from 'react-router-dom';
import './RoomInput.css';
import { ChannelContext } from '../connections/ChannelProvider';
import { EnumServiceType } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok, faTwitch } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useLocalStorage } from '../connections/LocalStorage.hook';

const { Search } = Input;

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function RoomInput() {
  const query = useQuery();
  const { join } = useContext(ChannelContext);
  const [service, setService] = useLocalStorage('last_service_used');
  const [form] = Form.useForm();

  const onServiceChange = (value: number) => {
    setService(value);
  };

  useEffect(() => {
    if (!service) setService(EnumServiceType.Twitch);
    const startAt = query.get('channel');
    if (startAt) setTimeout(() => join(startAt, service), 2000);
  }, []);

  return (
    <Form form={form}>
      <Form.Item name="room" label="Channel" rules={[{ required: true }]}>
        <Search
          addonBefore={
            <Select
              defaultValue={service}
              onChange={onServiceChange}
              options={[
                {
                  value: 1,
                  label: <FontAwesomeIcon icon={faTwitch as IconProp} />,
                },
                {
                  value: 3,
                  label: <FontAwesomeIcon icon={faTiktok as IconProp} />,
                },
              ]}
            />
          }
          placeholder="Enter a channel"
          allowClear
          enterButton="Enter"
          onSearch={(value) => join(value, service)}
        />
      </Form.Item>
    </Form>
  );
}
