import React, { useContext, useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import { ChannelEvents } from '../types';
import VerticalProgress, { adjustBounce } from './VerticalProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faAngry, faSadCry, faFaceGrinTongueWink, faRobot, faGrinSquintTears, faFaceSmile, faHandHolding } from '@fortawesome/free-solid-svg-icons';
import { ChannelContext } from '../connections/ChannelProvider';

export default function ProgressSentiment() {
  const { channel, events$ } = useContext(ChannelContext);
  const [info, setInfo] = useState<ChannelEvents>(new ChannelEvents());
  const [sub, setSub] = useState<Subscription>(null);

  const [praise, setPraise] = useState(0);
  const [joy, setJoy] = useState(0);
  const [thirsty, setThirsty] = useState(0);
  const [laugh, setLaugh] = useState(0);
  const [sad, setSad] = useState(0);
  const [bots, setBots] = useState(0);
  const [beggar, setBeggar] = useState(0);
  const [anger, setAnger] = useState(0);

  useEffect(() => {
    if (sub) sub.unsubscribe();
    if (events$) {
      setSub(
        events$.subscribe((event) => {
          setInfo((prev) => {
            adjustBounce(prev, event, 'praise', setPraise);
            adjustBounce(prev, event, 'thirsty', setThirsty);
            adjustBounce(prev, event, 'laugh', setLaugh);
            adjustBounce(prev, event, 'sad', setSad);
            adjustBounce(prev, event, 'bots', setBots);
            adjustBounce(prev, event, 'beggar', setBeggar);
            adjustBounce(prev, event, 'anger', setAnger);
            adjustBounce(prev, event, 'joy', setJoy);
            return event;
          });
        }),
      );
    }
    return () => {
      if (sub) sub.unsubscribe();
      setInfo(new ChannelEvents());
      setSub(null);
    };
  }, [channel, events$]);

  useEffect(() => {
    if (praise !== 0) setTimeout(() => setPraise(0), 3000);
    if (thirsty !== 0) setTimeout(() => setThirsty(0), 3000);
    if (laugh !== 0) setTimeout(() => setLaugh(0), 3000);
    if (sad !== 0) setTimeout(() => setSad(0), 3000);
    if (bots !== 0) setTimeout(() => setBots(0), 3000);
    if (beggar !== 0) setTimeout(() => setBeggar(0), 3000);
    if (anger !== 0) setTimeout(() => setAnger(0), 3000);
    if (joy !== 0) setTimeout(() => setJoy(0), 3000);
  }, [praise, thirsty, laugh, sad, bots, beggar, anger, joy]);

  return (
    <VerticalProgress
      progress={[
        {
          title: 'Bots',
          color: '#3B6A7B',
          bubbles: false,
          waves: false,
          dark: false,
          pct: Math.max(0, info.bots || 0),
          icon: <FontAwesomeIcon icon={faRobot} size={'xl'} bounce={bots === 1} fade={bots === -1} />,
        },
        {
          title: 'Thirsty',
          color: '#D3178E',
          bubbles: false,
          waves: false,
          dark: false,
          pct: Math.max(0, info.thirsty || 0),
          icon: <FontAwesomeIcon icon={faFaceGrinTongueWink} size={'xl'} bounce={thirsty === 1} fade={thirsty === -1} />,
        },
        {
          title: 'Praise',
          color: '#7A7600',
          bubbles: false,
          waves: false,
          dark: false,
          pct: Math.max(0, info.praise || 0),
          icon: <FontAwesomeIcon icon={faTrophy} size={'xl'} bounce={praise === 1} fade={praise === -1} />,
        },
        {
          title: 'Joy',
          color: '#FFC105', //F4C741
          bubbles: false,
          waves: false,
          dark: false,
          pct: Math.max(0, info.joy || 0),
          icon: <FontAwesomeIcon icon={faFaceSmile} size={'xl'} bounce={joy === 1} fade={joy === -1} />,
        },
        {
          title: 'Angry',
          color: '#B30000',
          bubbles: false,
          waves: false,
          dark: false,
          pct: Math.max(0, info.anger || 0),
          icon: <FontAwesomeIcon icon={faAngry} size={'xl'} bounce={anger === 1} fade={anger === -1} />,
        },
        {
          title: 'Sad',
          color: '#716895',
          bubbles: false,
          waves: false,
          dark: false,
          pct: Math.max(0, info.sad || 0),
          icon: <FontAwesomeIcon icon={faSadCry} size={'xl'} bounce={sad === 1} fade={sad === -1} />,
        },
        {
          title: 'Beggar',
          color: '#854700',
          bubbles: false,
          waves: false,
          dark: false,
          pct: Math.max(0, info.beggar || 0),
          icon: <FontAwesomeIcon icon={faHandHolding} size={'xl'} bounce={beggar === 1} fade={beggar === -1} />,
        },
        {
          title: 'Laugh',
          color: '#FF8800',
          bubbles: false,
          waves: false,
          dark: false,
          pct: Math.max(0, info.laugh || 0),
          icon: <FontAwesomeIcon icon={faGrinSquintTears} size={'xl'} bounce={laugh === 1} fade={laugh === -1} />,
        },
      ]}
    />
  );
}
