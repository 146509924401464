import React, { useEffect } from 'react';
import { Col, Row, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitch, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBiohazard, faGrinSquintTears, faWater } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const { Title, Paragraph } = Typography;

function About() {
  useEffect(() => {
    if (window['umami']) window['umami'].track();
  }, []);
  return (
    <Row justify="center">
      <Col xs={21}>
        <Typography>
          <Title>What is ChemoChat</Title>
          <Paragraph>How to engage with live chat, when there's just too much messages?</Paragraph>
          <Paragraph>
            Every streamer wants to engage with their audience, but reading live messages is impossible for popular hosts or a live show. There's just too much
            to read. It affects viewers too, especially those with vision or reading difficulties. The streamer has no choice but to ignore most of the
            messages.
          </Paragraph>
          <Paragraph>
            Every platform implements solutions to alleviate this problem: subscriber-only messages, paid messages so the host would return the favor by reading
            them; or the streamer resorts to creating their own parallel, private chat and interact only with those.
          </Paragraph>
          <Paragraph>
            ChemoChat has a more opinionated approach: as the popular wisdom goes, there's too much "cancer" on live chat and those toxic, boring, repetitive,
            noisy, cringy, uninteresting messages have to give space to the meaningful ones. All messages are clustered over sentiment analysis so anyone can
            still feel what's going on without reading them all.
          </Paragraph>
          <Paragraph>ChemoChat is dedicated to my late father. I couldn't cure his cancer, but I can cure chat cancer.</Paragraph>
          <Title level={2}>How to Use</Title>
          <Paragraph>
            All usage is subject to our <Link to="/terms">Terms & Conditions</Link>. Also bear in mind our guidelines regarding the&nbsp;
            <Link to="/privacy">Privacy</Link> of your data.
          </Paragraph>
          <Paragraph>
            ChemoChat is running an "open beta" test. Any live channel can be joined using a social login and creating a test subscription at our&nbsp;
            <Link to="/pricing">Pricing page</Link>. <strong>No charge will ever be made</strong> to any beta user during this testing period. Beta
            subscriptions <strong>will not</strong> be carried over to initial release.
          </Paragraph>
          <Paragraph>
            We call our "public" channels (marked with a green &nbsp;
            <FontAwesomeIcon icon={faTwitch as IconProp} color="green" title="social provider" /> logo) some pre-chosen popular channels so anyone can check
            what this service is about. Streamers who subscribe to ChemoChat have their channels listed (marked with a purple &nbsp;
            <FontAwesomeIcon icon={faTwitch as IconProp} color="purple" title="social provider" /> logo) and are available anytime they're live, for any viewer
            regardless of personal subscription. Channels not marked as "public" or "streamer" need an active subscription to be viewed with ChemoChat.
          </Paragraph>
          <Title level={2}>Live information</Title>
          <Paragraph>
            ChemoChat reads every message in a live channel and decides if it is "cancer"&nbsp;
            <FontAwesomeIcon icon={faBiohazard} title="cancer logo" />
            &nbsp;or not. Messages marked as such are not shown, but are still evaluated for sentiment. For example, a live chat being bombarded with laughter
            will have all those similar messages hidden but counted as "laugh"&nbsp;
            <FontAwesomeIcon icon={faGrinSquintTears} title="laughter logo" /> messages.
          </Paragraph>
          <Paragraph>Below the channel name there are a few status information regarding messaging speed, subs, gifts, cheers, et cetera.</Paragraph>
          <Paragraph>
            There are two stacked bar charts besides the channel messages. The first shows what percentage of cancer&nbsp;
            <FontAwesomeIcon icon={faBiohazard} title="cancer logo" />
            &nbsp;messages are being hidden, as well as "drowned"&nbsp;
            <FontAwesomeIcon icon={faWater} title="drowned logo" />
            &nbsp;messages - those happen when the channel has too many useful messages at a fast pace, so some which would be otherwise be shown have to be
            discarded based on a few criteria. The second bar chart represents channel sentiment detected over all messages. It is constantly updated to reflect
            the humor of the crowd, such as joy, sadness, anger or "thirsty" vibes. Clicking that bar opens a brief historical chart over how those vibes
            changed last few minutes.
          </Paragraph>
          <Paragraph>
            We are committed to make this tool accessible to screen readers. Many keyboard shorcuts can be used and information updates are available alongside
            the chat. If you have suggestions on how we can improve, reach us:&nbsp;
            <Link to="https://veilmail.io/chemochataccessible" target="_blank">
              accessible at chemo dot chat
            </Link>
            .
          </Paragraph>
          <Title level={2}>Roadmap</Title>
          <Paragraph>
            YouTube <FontAwesomeIcon icon={faYoutube} title="youtube logo" /> is unfortunately out of reach at this moment. They don't make available their Live
            Stream API to third-parties without explicit owner approval - only the owner of a channel can receive live stream info, and so only at a time in the
            future when we have demand from YouTube streamers to make their chat available through us we'll be able to deploy that feature.
          </Paragraph>
          <Paragraph>
            TikTok <FontAwesomeIcon icon={faTiktok} title="tiktok logo" /> has been implemented but integration is unstable due to the nature of their
            connection and API possibilities at the moment. We are however actively monitoring the situation to deploy its usage as soon as possible.
          </Paragraph>
        </Typography>
      </Col>
    </Row>
  );
}

export default About;
