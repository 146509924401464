import { Card, Divider, List, Space, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Channel, EnumPlanType, EnumServiceType } from '../types';
import { faTiktok, faTwitch, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ChannelContext } from '../connections/ChannelProvider';
import './ChatCard.css';
import { EyeOutlined } from '@ant-design/icons';
import { NotificationContext } from '../connections/NotificationProvider';
import useApi from '../connections/Api';

const { Paragraph, Title } = Typography;

function ChatCards() {
  const { req } = useApi();
  const { notification } = useContext(NotificationContext);
  const [open, setOpen] = useState<Channel[]>([]);
  const { channel, join } = useContext(ChannelContext);

  const select = (room: string, service: EnumServiceType) => {
    if (channel === room) return;
    join(room, service);
  };
  const onClick = (card: Channel) => {
    if (card.plan !== EnumPlanType.Deactivated) {
      select(card.name, card.service);
    }
  };

  const formatCount = (count: number): string => {
    if (count < 1000) return '' + count;
    return '' + Math.round((count * 10) / 1000) / 10;
  };

  const castService = (service: EnumServiceType): IconProp => {
    if (service === EnumServiceType.Twitch) return faTwitch as IconProp;
    else if (service === EnumServiceType.Tiktok) return faTiktok as IconProp;
    else if (service === EnumServiceType.YouTube) return faYoutube as IconProp;
    else return null;
  };
  const castPlan = (service: EnumPlanType): string => {
    if (service === EnumPlanType.FixedPublic) return 'green';
    else return 'purple';
  };

  useEffect(() => {
    req('/api/channels/public')
      .then((res) => {
        if (!res.success) return Promise.reject(res.message);
        let channels: Channel[] = res.data;
        channels = channels.sort((a, b) => b.viewer_count - a.viewer_count).slice(0, 6);
        // inform about YouTube
        channels.push({
          game_name: 'Help wanted: need streamers for dev testing',
          is_mature: false,
          thumbnail_url: '',
          name: 'YouTube',
          service: EnumServiceType.YouTube,
          plan: EnumPlanType.FuturePlans,
          viewer_count: 0,
          language: 'en',
        });
        // inform about Tiktok
        channels.push({
          game_name: 'TikTok might be unstable to connect. Try later if needed.',
          is_mature: false,
          thumbnail_url: '',
          name: 'TikTok',
          service: EnumServiceType.Tiktok,
          plan: EnumPlanType.FuturePlans,
          viewer_count: 0,
          language: 'en',
        });
        setOpen(channels);
      })
      .catch((err) => {
        notification({ type: 'warning', message: `Could not load public channels` });
      });
  }, []);

  return (
    <>
      <Typography>
        <Title>Channels</Title>
        <Paragraph>Browse public channels, or input a channel directly</Paragraph>
      </Typography>
      <List
        className="chat-cards"
        grid={{ gutter: 8, column: 2 }}
        dataSource={open}
        loading={!open || open.length === 0}
        renderItem={(c) => (
          <List.Item key={c.name}>
            {c.plan !== EnumPlanType.FuturePlans && (
              <Card bordered={false} style={{ backgroundColor: channel === c.name ? '#D5E2D3' : 'white' }} hoverable={true} onClick={() => onClick(c)}>
                <Card.Meta
                  avatar={<FontAwesomeIcon icon={castService(c.service)} color={castPlan(c.plan)} size="xl" />}
                  title={
                    <Space align="baseline">
                      <Title level={5}>{c.name}</Title>
                    </Space>
                  }
                ></Card.Meta>
                <Divider />
                <Space align="start">
                  <img src={`img/icons/${c.language}.svg`} width="20px" height="20px" alt={c.language} />
                  <Typography>
                    <Paragraph className="game-name">{c.game_name}</Paragraph>
                  </Typography>
                </Space>
                <br />
                <Space align="start">
                  <EyeOutlined style={{ fontSize: '20px' }} />
                  <Typography>
                    <Paragraph>{formatCount(c.viewer_count) + (c.viewer_count > 1000 ? 'k' : '')} viewers</Paragraph>
                  </Typography>
                </Space>
              </Card>
            )}
            {c.plan === EnumPlanType.FuturePlans && (
              <Card bordered={false} style={{ backgroundColor: '#ececec' }} hoverable={false}>
                <Card.Meta
                  avatar={<FontAwesomeIcon icon={castService(c.service)} color={'grey'} size="xl" />}
                  title={
                    <Space align="baseline">
                      <Title level={4}>{c.name}</Title>
                    </Space>
                  }
                ></Card.Meta>
                <Divider />
                <Space align="start">
                  <Typography>
                    <Paragraph>{c.game_name}</Paragraph>
                  </Typography>
                </Space>
              </Card>
            )}
          </List.Item>
        )}
      />
    </>
  );
}

export default ChatCards;
