import { Socket } from 'socket.io-client';
import { Observable } from 'rxjs';
import { Session, Provider } from '@supabase/supabase-js';
import React from 'react';

export type SocketConnection = {
  socket: Socket;
  socketConnected: boolean;
};

export type UserConnection = {
  session: Session | null;
  loginSocial: (social: Provider) => void;
  loginEmail: () => void;
  loginLocal: () => void;
  logout: () => void;
};

export type ChannelConnection = {
  channel: string;
  service: EnumServiceType;
  channelConnected: boolean;
  events$: Observable<ChannelEvents>;
  messages$: Observable<ChatMessage>;
  chart$: Observable<ChannelEvents & { name: string }>;
  info: any; // twitch json info
  join: (joinRoom: string, service: EnumServiceType, original?: boolean) => void;
  leave: (room?: string) => void;
};

export type MessageConnection = {
  messages$: Observable<ChatMessage>;
  original?: boolean;
  deslang?: boolean;
  msgsSize?: number;
  sizeCss?: string;
  ariaLive?: boolean;
};

export type AriaConnection = {
  ariaRead: React.Dispatch<React.SetStateAction<string>>;
  ariaFlipRead: (state: string, current: number, read: string) => void;
  ariaDeltaRead: (current: number, read: string) => void;
};

export type PassSetRoom = {
  setRoom: (r: (prevRoom) => string) => void;
};

export type PassNamespace = {
  namespace?: string;
};

export type PassChatRoom = {
  room: string;
  service: EnumServiceType;
};

export type NotificationType = {
  message: string;
  type: string;
  duration?: number;
  clear?: boolean;
};

export type PassChatSize = PassChatRoom & {
  msgsSize?: number;
  original?: boolean;
  mustLoad?: boolean;
  fixed4?: boolean;
};

export type JoinRoom = {
  room: string;
  original: boolean;
};

export type ChatMessage = {
  id: string;
  room: string;
  roomId: string;
  text: string;
  deslang: string;
  badges: any[];
  name: string;
  color: string;
  userId: string;
  isMod: boolean;
  highlight: boolean;
  time: string;
  rawText?: string;
};

export type Sentiment = {
  anger: number;
  joy: number;
  sad: number;
  bots: number;
  thirsty: number;
  beggar: number;
  praise: number;
  laugh: number;
};

export class ChannelEvents {
  room: string;
  cheers: number;
  subs: number;
  raid: number;
  gifts: number;
  tiers: number;
  cancer: number;
  drops: number;
  speed: number; // msgs/sec

  anger: number;
  joy: number;
  sad: number;
  bots: number;
  thirsty: number;
  beggar: number;
  praise: number;
  laugh: number;
}

export enum EnumServiceType {
  YouTube = 2,
  Twitch = 1,
  Tiktok = 3,
}

export const castServiceName = (service: EnumServiceType): string => {
  if (service === EnumServiceType.Twitch) return 'Twitch';
  else if (service === EnumServiceType.Tiktok) return 'TikTok';
  else if (service === EnumServiceType.YouTube) return 'YouTube';
  else return null;
};

export enum EnumPlanType {
  FixedPublic = 90,
  Private = 10,
  Streamer = 20,
  Deactivated = 30,
  FuturePlans = 40,
}

export class Channel {
  name: string;
  service: EnumServiceType;
  plan: EnumPlanType;
  game_name: string;
  viewer_count: number;
  language: string;
  thumbnail_url: string;
  is_mature: boolean;
}

export type AuthConnection = {
  domain: string;
  getUserMetadata: () => Promise<any>;
  token$: Observable<string>;
};
