import React, { useEffect } from 'react';
import { Col, Divider, Row, Table, Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Title, Paragraph } = Typography;

function Terms() {
  const dataSource = [
    {
      key: '1',
      type: 'Severity 1',
      def: 'An error that disables or materially impairs the major function of the Service or Customer’s use of any major function of the Service and affects every user in Customer’s tenant.',
      res: 'Not provided in this SLA',
    },
    {
      key: '2',
      type: 'Severity 2',
      def: ' An error that impacts major functionality and results in significant performance degradation and affects some users.',
      res: 'Not provided in this SLA',
    },
    {
      key: '3',
      type: 'Severity 3',
      def: 'A partial, non-critical loss of use of the service with a medium-to-low impact on Customer’s usage.',
      res: 'Not provided in this SLA',
    },
    {
      key: '4',
      type: 'Severity 4',
      def: 'Minor functions fail or performance is slow for some users.',
      res: 'Not provided in this SLA',
    },
  ];
  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Definition',
      dataIndex: 'def',
      key: 'def',
    },
    {
      title: 'Response Time',
      dataIndex: 'res',
      key: 'res',
    },
  ];
  useEffect(() => {
    if (window['umami']) window['umami'].track();
  }, []);
  return (
    <Row justify="center">
      <Col xs={21}>
        <Typography>
          <Title>ChemoChat Agreement to Terms and Conditions</Title>
          <Paragraph>
            <strong>
              This ChemoChat Agreement (the &quot;Agreement&quot;) is made effective as of the start of service usage (the &quot;Effective Date&quot;) by and
              between a User (&quot;Authorized User&quot;), or the company executing a Service Order and its Affiliates (&quot;Customer&quot;), and ChemoChat
              service, its parent company Cellide Ltda. and its Affiliates (&quot;ChemoChat&quot;). The Agreement consists of the General Terms and Conditions,
              the Service Level Agreement (Schedule A) and one or more Service Order(s) &amp; General Business Terms.
            </strong>
          </Paragraph>
          <Divider></Divider>
          <Title level={2}>General Terms and Conditions</Title>
          <Title level={3}>1. DEFINITIONS</Title>
          <Paragraph>
            Unless the context or circumstances clearly suggest otherwise, the following capitalized terms shall have the meanings stated below:
          </Paragraph>
          <Paragraph>
            &quot;Affiliate&quot; means any entity, firm, or corporation, directly or indirectly, through one or more intermediaries, controlling, controlled
            by, or under common control with another entity, firm, or corporation.
          </Paragraph>
          <Paragraph>
            &quot;Authorized User&quot; means an individual who act as its own Customer; or is authorized by Customer to use the Services on Customer’s behalf,
            and for whom access to the Services has been purchased hereunder.
          </Paragraph>
          <Paragraph>
            &quot;Customer Data&quot; means all information used, processed, or stored by Customer or on Customer’s behalf, or provided to ChemoChat for such
            processing or storage, as well as any information derived from such information, during Customer’s use of the Services.
          </Paragraph>
          <Paragraph>&quot;Service Order&quot; means an order form for ChemoChat Services entered into by ChemoChat and Customer.</Paragraph>
          <Paragraph>&quot;Services&quot; means the ChemoChat software-as-a-service offerings set forth in the Service Order.</Paragraph>
          <Paragraph>&quot;Subscription Period&quot; means the period set forth in the General Business Terms.</Paragraph>
          <Paragraph>&quot;Channel&quot; means a third-party live channel made available outside of ChemoChat by a Streaming Service Provider.</Paragraph>
          <Paragraph>&quot;SLA&quot; means Service Level Agreement between ChemoChat and its Customers.</Paragraph>
          <Paragraph>&quot;Payment Provider&quot; means the affiliate third-party ChemoChat uses to process payments, currently Stripe.com</Paragraph>
          <Title level={3}>2. ACCESS AND USE</Title>
          <Paragraph>
            2.1. ChemoChat grants Customer a non-exclusive, non-transferable right to access and use the Services during the Subscription Period. A customized
            Service Order shall specify the Services, Subscription Period, fees, the total number of Authorized Users and any other relevant details.
          </Paragraph>
          <Paragraph>
            2.2. ChemoChat grants Customer a non-exclusive, non-transferable right to access and use the Services during the Subscription Period, with a
            standard Service Order abiding to this Agreement, when such Subscription was made without a customized Order between Customer and ChemoChat.
          </Paragraph>
          <Paragraph>
            2.3. ChemoChat allows a Customer a non-exclusive, non-transferable right to access and use a limited portion of the Services free of charge, at a
            non-binding SLA at discretion of ChemoChat, adjustable at any given time without prior communication.
          </Paragraph>
          <Paragraph>
            2.4. ChemoChat may freely modify the Agreement if, but not limited to, necessary to the continuity of its Services and its standards; if required by
            law; to better clarify a part of this Agreement; when its modification does not implicate a greater policy obligation to its Customer or a
            significant change of quality of Services.
          </Paragraph>
          <Title level={3}>3. CHEMOCHAT OBLIGATIONS</Title>
          <Paragraph>3.1. ChemoChat shall provide the Services as-is, without obligations of any particular feature or capability.</Paragraph>
          <Paragraph>
            3.2. ChemoChat represents and warrants that it complies, and shall continue to comply with, all applicable laws, regulations, and ordinances.
          </Paragraph>
          <Title level={3}>4. CUSTOMER OBLIGATIONS</Title>
          <Paragraph>
            4.1. Customer is responsible for providing its Authorized Users with the means through which the Services are accessed or used, as is necessary for
            ChemoChat to perform the Services; Customer is also responsible of communicating ChemoChat which users are currently authorized at a given time.
          </Paragraph>
          <Paragraph>
            4.2. Customer represents and warrants that it complies with all applicable laws, rules, and regulations applicable to its use of the Services.
          </Paragraph>
          <Paragraph>
            4.3. Customer shall access the Services only to the extent authorized by ChemoChat, and it shall ensure only Authorized Users access the Services.
          </Paragraph>
          <Paragraph>
            4.4. ChemoChat shall not be responsible or liable for the portion of any delay or failure of performance caused in whole or in part by Customer’s
            delay in performing, or failure to perform, any of Customer’s obligations under this Agreement.
          </Paragraph>
          <Title level={3}>5. INVOICING AND PAYMENT</Title>
          <Paragraph>
            5.1. Unless otherwise agreed in the Service Order, ChemoChat will issue monthly invoices to Customer through our Payment Provider during the
            Subscription Period, and Customer will pay ChemoChat all Undisputed Fees within 30 days of the date of invoice. An Undisputed Fee means an invoice,
            or portion thereof, that has been received by Customer, and about which Customer has not in good faith raised concerns within 30 days of the invoice
            being issued and payment becoming due. Any payments made by Customer shall be nonrefundable, unless otherwise expressly provided herein.
          </Paragraph>
          <Paragraph>
            5.2. Customer is responsible for all sales, use, and excise taxes, and any other similar taxes, duties, and charges of any kind imposed by any
            federal, state, or local governmental or regulatory authority on any amounts payable by Customer hereunder, other than any taxes imposed on
            ChemoChat's income. Customer is responsible for any fees resulting from or associated with Customer’s requirement that ChemoChat use a third-party
            billing agent, portal or system.
          </Paragraph>
          <Title level={3}>6. OWNERSHIP OF DATA</Title>
          <Paragraph>
            6.1. Customer shall retain, and have all ownership, rights, title, and interest in and to, all Customer Data. No right, title, or interest in or to
            Customer Data whatsoever shall be transferred to ChemoChat by virtue of the Services, the Service Order or otherwise. Customer is solely responsible
            for the content, quality and accuracy of Customer Data as made available by Customer and/or Affiliates; and for ensuring that such Customer Data
            complies with applicable laws and regulations including, but not limited to, the EU General Data Protection Regulation (2016/679).
          </Paragraph>
          <Paragraph>
            6.2. ChemoChat shall retain and have all ownership, rights, title, and interest, including all intellectual property rights, in and to the Services
            and all software, improvements, enhancements and modifications thereto, including but not limited to, all models, methodologies, tools, procedures,
            documentation, know-how and processes that ChemoChat will use in delivering the Services.
          </Paragraph>
          <Paragraph>
            6.3. ChemoChat does not record or in any other way store Customer data regarding chat messages or personally identifiable information.
          </Paragraph>
          <Paragraph>
            6.4. ChemoChat may use non-personal, non-identifiable Customer Data for security and operations management and to improve and enhance our Services.
          </Paragraph>
          <Title level={3}>7. INDEMNIFICATION</Title>
          <Paragraph>
            7.1. ChemoChat shall defend and indemnify Customer against all direct losses, liabilities, damages, costs, and expenses (&quot;Losses&quot;)
            incurred by Customer resulting from any action or claim from a third party that the Services, or Customer’s use of the Services in accordance with
            this Agreement, infringe or misappropriate such third party’s intellectual property rights.
          </Paragraph>
          <Paragraph>
            7.2. Customer shall defend and indemnify ChemoChat against all direct Losses incurred by ChemoChat resulting from any action or claim from a third
            party that the Customer Data, or any use of the Customer Data in accordance with this Agreement, infringes or misappropriates such third party’s
            intellectual property rights.
          </Paragraph>
          <Paragraph>
            7.3. The above obligations for ChemoChat to defend and indemnify will not apply in the event that a claim arises from or relates to: (i) use of the
            Services not in accordance with the Agreement; (ii) Customer’s use of the Services in violation of applicable laws; (iii) any modification,
            alteration or conversion of the Services not created or approved in writing by ChemoChat; or (iv) Customer’s gross negligence or willful misconduct;
            or (v) Customer’s use of third party services, systems, applications or programs.
          </Paragraph>
          <Paragraph>
            7.4. Each party’s obligations according to this Section 7 are subject to informing the indemnifying party of the alleged infringement or
            misappropriation as soon as reasonably practicable after becoming aware of the same and that the indemnified party takes no prejudicial action,
            including admitting liability or proposing settlement. Each party shall have sole discretion and authority to negotiate, settle, litigate, or
            otherwise dispose of the alleged infringement or misappropriation.
          </Paragraph>
          <Paragraph>
            7.5. If any part of the Services becomes subject to a claim of infringement for which ChemoChat must indemnify Customer as described above,
            ChemoChat may at its option and expense attempt to remedy the infringement by: (i) procuring the right for Customer to continue to use the Services,
            or (ii) modifying or replacing the Services so that it becomes non-infringing. If ChemoChat determines that neither alternative is reasonably
            available, Provider may terminate the affected Service Order and provide Customer with a pro-rata refund of any unused pre-paid fees paid for the
            period following termination.
          </Paragraph>
          <Paragraph>
            7.6. This section states the sole liability and the exclusive remedy of each party with respect to any indemnification claims arising out of or
            related to this Agreement.
          </Paragraph>
          <Title level={3}>8. LIMITATION OF LIABILITY</Title>
          <Paragraph>
            8.1. Exclusion of Damages: EXCEPT FOR: (I) A PARTY’S INDEMNIFICATION OBLIGATIONS ARISING FROM INFRINGEMENT OF A THIRD PARTY’S INTELLECTUAL PROPERTY
            RIGHTS PURSUANT TO SECTION 7, (II) FRAUD OR WILFUL MISCONDUCT BY EITHER PARTY, OR (III) BREACH OF CUSTOMER'S PAYMENT OBLIGATIONS, NEITHER PARTY WILL
            BE LIABLE TO THE OTHER FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES, OR ANY LOSS OF INCOME, SALES, DATA, PROFITS,
            REVENUE OR BUSINESS INTERRUPTION, GOODWILL, REPUTATION OR THE COST OF SUBSTITUTE SERVICES OR OTHER ECONOMIC LOSS, ARISING OUT OF OR IN CONNECTION
            WITH THIS AGREEMENT OR THE PROVISION OF THE SERVICES, WHETHER SUCH LIABILITY ARISES FROM ANY CLAIM BASED ON CONTRACT, WARRANTY, TORT (INCLUDING
            NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, AND WHETHER SUCH PARTY WAS ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
          </Paragraph>
          <Paragraph>
            8.2. Total Liability. IN NO EVENT WILL EITHER PARTY’S TOTAL LIABILITY IN CONNECTION WITH THIS AGREEMENT OR THE PROVISION AND USE OF THE SERVICES
            EXCEED THE FEES ACTUALLY PAID BY CUSTOMER TO CHEMOCHAT IN THE THREE (3) MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO THE CLAIM, REGARDLESS OF THE
            LEGAL OR EQUITABLE THEORY ON WHICH THE CLAIM OR LIABILITY IS BASED, AND WHETHER THE PARTY WAS ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
          </Paragraph>
          <Title level={3}>9. CONFIDENTIALITY</Title>
          <Paragraph>
            9.1. During the term of the Agreement, either party may disclose (the &quot;Disclosing Party&quot;) to the other party (the &quot;Receiving
            Party&quot;) know-how, information, ideas, or materials of a technical or creative nature, designs and specifications, computer source and object
            code, and other materials and concepts relating to either party’s products, services, processes, technology or other intellectual property rights,
            orally or in writing, that is designated as confidential or that reasonably should be understood to be confidential given the nature of the
            information (collectively &quot;Confidential Information&quot;). Confidential Information does not include any information that (i) is or becomes
            generally known to the public without breach of any obligation owed to the Disclosing Party, (ii) was known to the Receiving Party prior to its
            disclosure by the Disclosing Party without breach of any obligation owed to the Disclosing Party, (iii) is received from a third party without
            breach of any obligation owed to the Disclosing Party, or (iv) was independently developed by the Receiving Party.
          </Paragraph>
          <Paragraph>
            9.2. The Receiving Party will use the same degree of care to protect the confidentiality of the Disclosing Party’s Confidential Information that it
            uses to protect its own Confidential Information (but not less than reasonable care). The Receiving Party may disclose Confidential Information of
            the Disclosing Party to (i) professional advisors where necessary and (ii) to the extent compelled by law, provided the Receiving Party gives the
            Disclosing Party prior notice of compelled disclosure, to the extent legally permitted.
          </Paragraph>
          <Paragraph>
            9.3. The Parties agree that the Disclosing Party may suffer irreparable injury if its Confidential Information is made public, released to a third
            party, or otherwise disclosed in breach of the Agreement, and that the Disclosing Party shall be entitled to seek injunctive relief against a
            threatened breach or continuation of any such breach and, in the event of such breach, an award of actual and exemplary damages to the extent
            legally possible from the relevant jurisdiction.
          </Paragraph>
          <Title level={3}>10. DATA SECURITY</Title>
          <Paragraph>
            10.1. ChemoChat shall (i) maintain commercially reasonable administrative, technical and physical safeguards designed for the protection,
            confidentiality and integrity of Customer Data, and that meet or exceed the requirements of prevailing industry standards or an applicable third
            party security assurance standard such as ISO 27001, SOC 2 or ISAE 3402; and (ii) access and use Customer Data solely to perform its obligations in
            accordance with the terms of the Agreement, and as otherwise expressly permitted in this Agreement.
          </Paragraph>
          <Title level={3}>11. TERMINATION</Title>
          <Paragraph>
            11.1. ChemoChat may terminate the Agreement and any related Service Order without notice with immediate effect in the event that (1) the other party
            materially breaches its obligations under this Agreement, and: (i) such breach is incapable of remedy; (ii) if the breach is capable of remedy, and
            the party does not remedy the breach within thirty (30) days of receiving notice from the other party of such breach; (2) the other party becomes
            bankrupt, ceases payments, applies for company reconstruction, goes into liquidation or otherwise may be considered to be insolvent; or (3)
            ChemoChat becomes unable to continue its delivery of the Services due to technical, commercial or financial reasons.
          </Paragraph>
          <Paragraph>
            11.2. Customer may terminate the Agreement for customized Service Order upon written notice with immediate effect in the event that (1) the other
            party materially breaches its obligations under this Agreement, and: (i) such breach is incapable of remedy; (ii) if the breach is capable of
            remedy, and the party does not remedy the breach within thirty (30) days of receiving notice from the other party of such breach; or (2) the other
            party becomes bankrupt, ceases payments, applies for company reconstruction, goes into liquidation or otherwise may be considered to be insolvent.
            Termination must be made in writing in order to be valid.
          </Paragraph>
          <Paragraph>
            11.3. Customer may terminate the Agreement without notice and without prejudice while in active Subscription Period if its related Service Order was
            a standard, widely available, automated process of Subscription Order not made custom for the Customer.
          </Paragraph>
          <Paragraph>
            11.4. Effect of expiration or termination: Upon expiration or earlier termination of this Agreement, Customer and its Authorized Users shall
            immediately discontinue use of the Services and shall, as applicable, delete, destroy or return all copies of the Services, or components thereof,
            including any source code relating thereto, from their computer systems.
          </Paragraph>
          <Title level={3}>12. GENERAL PROVISIONS</Title>
          <Paragraph>
            12.1. The Agreement constitutes the sole and entire agreement of the parties with respect to the subject matter and supersedes all prior
            understandings or agreements, both written and oral. In the event of any inconsistency between the Service Order and General Business Terms and
            these General Terms and Conditions, the Service Order and General Business Terms govern.
          </Paragraph>
          <Paragraph>
            12.2. Neither party may assign any of its rights or obligations under the Agreement without the other party’s prior written consent, which shall not
            be unreasonably withheld. Notwithstanding the foregoing, either party may assign any and all of its rights and obligations under the Agreement to a
            successor in interest in the event of a merger or acquisition or to an affiliate, upon written notice to the other party.
          </Paragraph>
          <Paragraph>
            12.3. In no event shall either party be liable to the other party for any failure or delay in performing its obligations under the Agreement (except
            for any obligations to make payments), if and to the extent such failure or delay is caused by any circumstances beyond such party's reasonable
            control, including but not limited to acts of God, flood, fire, earthquake, explosion, war, terrorism, invasion, epidemic, riot or other civil
            unrest, strikes, labor stoppages or slowdowns or other industrial disturbances, or passage of law or any action taken by a governmental or public
            authority, including imposing an embargo.
          </Paragraph>
          <Divider></Divider>
          <Title level={2}>Schedule A – Service Level Agreement</Title>
          <Paragraph>This Service Level Agreement ("SLA") is attached to and made a part of the Agreement.</Paragraph>
          <Title level={3}>1. Availability</Title>
          <Paragraph>
            1.1. ChemoChat provides Customer access to each Service 24x7 with a goal of availability of at least 98% (the &quot;Availability Commitment&quot;).
            Availability is measured generally for the Service.
          </Paragraph>
          <Paragraph>
            1.1.1 Goal not being reached at any given time of month will not incur in any penalties for ChemoChat as this is only a desired target.
          </Paragraph>
          <Paragraph>
            1.1.2 Channel availability or streaming hours in a given month is not a responsability of ChemoChat's Services and does not count towards SLA
            metrics.
          </Paragraph>
          <Paragraph>
            1.2. &quot;Availability&quot; is ([total minutes in a calendar month – total minutes of Service Outage for a Service]/total minutes in that calendar
            month) x 100.
          </Paragraph>
          <Paragraph>
            1.3. &quot;Service Outage&quot; means an event that renders each Service unavailable to all or some of Customer’s users, excluding Scheduled
            Downtime or Excluded Downtime, at discretion of ChemoChat.
          </Paragraph>
          <Paragraph>
            1.4. &quot;Scheduled Downtime&quot; means scheduled infrastructure changes to the Services. Scheduled Downtime will occur during non-core business
            hours (0-6AM UTC).
          </Paragraph>
          <Paragraph>
            1.5. &quot;Excluded Downtime&quot; means downtime caused by factors outside of ChemoChat’s reasonable control, including any force majeure event,
            internet services unavailability outside of ChemoChat’s platform, any downtime resulting from outages of third-party connections or utilities, and
            actions or inactions of Customer.
          </Paragraph>
          <Title level={3}>2. Support</Title>
          <Paragraph>
            2.1. Customer will direct all support requests to&nbsp;
            <Link to="https://veilmail.io/chemochatsupport" target="_blank">
              support at chemo dot chat
            </Link>
            &nbsp; (the &quot;Support&quot;).
          </Paragraph>
          <Paragraph>
            2.2. Customer will reasonably self-diagnose each support issue with a severity level designation in accordance with the definitions herein and
            specify its recommendation to ChemoChat in its support request email. ChemoChat will validate the severity designation and notify Customer of any
            adjustments higher or lower.
          </Paragraph>
          <Paragraph>
            2.3. ChemoChat can not respond to each support request – unless stated in a custom SLA with a particular Customer - but will work to resolution of
            any issue taking into consideration its severity and impact on Customer’s business operations. A resolution may consist of a workaround, fix,
            delivery of information or other reasonable solution to the issue. If ChemoChat resolves the support request by way of a workaround accepted by
            Customer, the severity level may be reduced.
          </Paragraph>
          <Paragraph>
            2.4. Following a support request, Customer will respond to ChemoChat’s requests for additional information and implement recommended solutions in a
            timely manner. For Severity 1 and 2 issues, Customer will remain reasonably accessible for troubleshooting until the issue is resolved.
          </Paragraph>

          <Table columns={columns} dataSource={dataSource} pagination={false} />
        </Typography>
      </Col>
    </Row>
  );
}

export default Terms;
