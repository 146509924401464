import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, Card, Col, Divider, Form, Row, Modal, Typography, Input } from 'antd';
import './Settings.css';
import { UserContext } from '../auth/AuthProvider';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { faTwitch, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useApi from '../connections/Api';
import { EnumServiceType } from '../types';
import { NotificationContext } from '../connections/NotificationProvider';

const { Title, Paragraph } = Typography;

type FieldType = {
  email?: string;
  subscription?: string;
};

function Settings() {
  const { notification } = useContext(NotificationContext);
  const { session } = useContext(UserContext);
  const { req } = useApi();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [data, setData] = useState({ email: '', subscription: '', promotions: { twitch: null, youtube: null, tiktok: null } });
  const [twitchKey, setTwitchKey] = useState(null);
  const [tiktokKey, setTiktokKey] = useState(null);

  const onManage = () => {
    window.location.href = 'https://billing.stripe.com/p/login/test_3cs9CH41fbBQeTC9AA';
  };

  const onSubmit = (values) => {
    if (window['umami']) window['umami'].track('promoting-channels');
    return req('/api/user/promote', values)
      .then((json) => {
        if (json.success) notification({ type: 'success', message: 'settings saved' });
        else notification({ type: 'warning', message: json.data.message });
      })
      .catch((e) => {
        notification({ type: 'error', message: e.message });
      });
  };

  const exclusion = async () => {
    if (window['umami']) window['umami'].track('excluding-user', { name: 'api/exclude' });
    const url = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_API}/api/exclude`;
    const token = session ? session.access_token : '';
    return fetch(url, { headers: { Authorization: 'Bearer ' + token } }).then(async (res) => {
      if (!res.ok) {
        const reason = await res.text();
        setError(reason);
      } else navigate('/');
    });
  };

  const errorConfirmation = () => {
    if (window['umami']) window['umami'].track('excluding-user', { name: 'errorConfirmation' });
    Modal.info({
      title: 'Error Excluding User',
      icon: <ExclamationCircleFilled />,
      content: (
        <Typography>
          <Paragraph>We are sorry but there was an error with your request.</Paragraph>
          <Paragraph code copyable>
            {error}
          </Paragraph>
          <Paragraph>Please try again at a later time.</Paragraph>
          <Paragraph>You can also try managing your Subscription directly with Stripe, or contacting us: support at chemo dot chat.</Paragraph>
          <Paragraph>We are deeply sorry for this inconvenience.</Paragraph>
        </Typography>
      ),
      okText: 'I understand',
      onOk: () => {
        setError(null);
        Modal.destroyAll();
      },
    });
  };

  const showConfirmation = () => {
    if (window['umami']) window['umami'].track('excluding-user', { name: 'showConfirmation' });
    Modal.confirm({
      title: 'Excluding User',
      icon: <ExclamationCircleFilled />,
      content: (
        <Typography>
          <Paragraph>To avoid errors, please confirm the exclusion one more time.</Paragraph>
        </Typography>
      ),
      okText: 'Yes, I am certain',
      okType: 'danger',
      cancelText: 'No, take me back',
      async onOk() {
        return exclusion();
      },
      onCancel() {
        console.log('canceled');
      },
    });
  };

  const showDeleteConfirm = () => {
    if (window['umami']) window['umami'].track('excluding-user', { name: 'showDeleteConfirm' });
    Modal.confirm({
      title: 'Excluding User',
      icon: <ExclamationCircleFilled />,
      content: (
        <Typography>
          <Paragraph>
            You are about to exclude your own User. <strong>This action cannot be undone</strong>.
          </Paragraph>
          <Paragraph>Your social login will be removed. If you have a standing Subscription, it will be cancelled.</Paragraph>
          <Paragraph>You would have to reacreate your User with a social login to be able to use ChemoChat in its fullest.</Paragraph>
          <Paragraph>Are you sure of this action?</Paragraph>
        </Typography>
      ),
      okText: 'Yes, remove my User',
      okType: 'danger',
      cancelText: 'No, take me back',
      async onOk() {
        return showConfirmation();
      },
      onCancel() {
        console.log('canceled');
      },
    });
  };

  const generateKey = async (service: EnumServiceType, room: string): Promise<string> => {
    if (window['umami']) window['umami'].track('overlay-key', { name: 'generate', service });
    return req('/api/overlay-key', { service, room })
      .then((json) => {
        return json.token;
      })
      .catch((e) => {
        console.error(e);
        return 'error generating key';
      });
  };

  useEffect(() => {
    if (window['umami']) window['umami'].track();
    req('/api/user/promote')
      .then((json) => {
        setData({ ...data, promotions: json });
      })
      .then(() => {
        if (session) {
          setData((prev) => ({
            ...data,
            email: session.user.email,
            subscription: session.user.user_metadata.plan || 'None',
          }));
        }
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (error) {
      errorConfirmation();
    }
  }, [error]);

  return (
    <Row>
      <Col xs={{ span: 22, offset: 1 }} lg={{ span: 8, offset: 8 }} xxl={{ span: 6, offset: 9 }}>
        {session ? (
          <Card className="settingsCard">
            <Card.Meta
              avatar={<Avatar size="large" src={session.user.user_metadata.avatar_url} alt="" />}
              title={
                <Typography>
                  <Title level={2}>{session.user.user_metadata.nickname}</Title>
                </Typography>
              }
            />
            <Typography>
              <Title level={3}>Settings</Title>
              <Divider />
            </Typography>
            <Form name="settings" wrapperCol={{ span: 24 }} onFinish={onSubmit} autoComplete="off">
              <Form.Item<FieldType> label="Registered email">{data.email}</Form.Item>
              <Form.Item<FieldType> label="Subscription">{data.subscription}</Form.Item>

              {data.subscription?.toLowerCase() === 'champion' && (
                <>
                  <Form.Item label="Your Promoted Channels"></Form.Item>

                  <Form.Item name="twitch">
                    <Input
                      key="twitch"
                      addonBefore={<FontAwesomeIcon icon={faTwitch as IconProp} title="Twitch" />}
                      placeholder="name handler for a Twitch channel"
                      value={data.promotions.twitch}
                    />
                  </Form.Item>
                  {data.promotions.twitch && (
                    <Form.Item name="twitch_key">
                      <Button type="default" onClick={() => setTwitchKey(generateKey(EnumServiceType.Twitch, data.promotions.twitch))}></Button>
                      {twitchKey && <span>Overlay Key: ${twitchKey}</span>}
                    </Form.Item>
                  )}

                  <Form.Item name="tiktok">
                    <Input
                      key="tiktok"
                      addonBefore={<FontAwesomeIcon icon={faTiktok as IconProp} title="TikTok" />}
                      placeholder="name handler for a TikTok channel"
                      value={data.promotions.tiktok}
                    />
                  </Form.Item>
                  {data.promotions.tiktok && (
                    <Form.Item name="tiktok_key">
                      <Button type="default" onClick={() => setTiktokKey(generateKey(EnumServiceType.Tiktok, data.promotions.tiktok))}></Button>
                      {tiktokKey && <span>Overlay Key: ${tiktokKey}</span>}
                    </Form.Item>
                  )}

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Form.Item>
                </>
              )}

              <Form.Item>
                <Button type="default" htmlType="button" onClick={onManage}>
                  Manage Subscription
                </Button>
              </Form.Item>

              <Form.Item style={{ float: 'right' }}>
                <Button danger type="dashed" onClick={showDeleteConfirm}>
                  Delete User
                </Button>
              </Form.Item>
            </Form>
          </Card>
        ) : (
          <Card className="settingsCard">
            <Typography>
              <Title level={3}>Settings</Title>
              <Divider />
              <Paragraph>You need to be logged in to see this page.</Paragraph>
            </Typography>
          </Card>
        )}
      </Col>
    </Row>
  );
}

export default Settings;
