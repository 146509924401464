import React, { useContext, useEffect } from 'react';
import { Avatar, Typography, Card, Divider, Space, Skeleton } from 'antd';
import Messages from './Messages';
import Stats from './Stats';
import { PassChatSize } from '../types';
import './Room.css';
import ProgressCancer from '../progress/ProgressCancer';
import ProgressSentiment from '../progress/ProgressSentiment';
import { ChannelContext } from '../connections/ChannelProvider';
import { SocketContext } from '../connections/SocketProvider';

const { Title } = Typography;
const { Meta } = Card;

function FixedRoom({ room, service, msgsSize = 11, mustLoad, fixed4 = false }: PassChatSize) {
  const { socket } = useContext(SocketContext);
  const { join, leave, info, messages$, channelConnected } = useContext(ChannelContext);

  useEffect(() => {
    if (socket && mustLoad && !channelConnected) {
      join(room, service, false);
    }
    return () => {
      if (mustLoad) leave(room);
    };
  }, [socket, channelConnected]);

  return (
    <Space>
      <Card className="roomCard">
        {info ? (
          <>
            <Meta
              avatar={<Avatar size="large" src={info.broadcaster_image} alt="" />}
              className="broadcasterInfo"
              title={
                <Typography>
                  <Title level={2} className="broadcasterName">
                    {info.broadcaster_name}
                  </Title>
                </Typography>
              }
            />
            <Typography>
              <Title level={3}> {info.game_name}</Title>
            </Typography>
          </>
        ) : (
          <Skeleton active avatar={{ shape: 'circle' }} paragraph={false} />
        )}
        <Divider />
        <Stats fixed4={fixed4} />
        <Divider />
        <Messages msgsSize={msgsSize} messages$={messages$} />
      </Card>
      <ProgressCancer />
      <ProgressSentiment />
    </Space>
  );
}

export default FixedRoom;
