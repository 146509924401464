import React, { useEffect } from 'react';
import { Col, Divider, Row, Typography } from 'antd';

const { Title, Paragraph } = Typography;

function Refunds() {
  useEffect(() => {
    if (window['umami']) window['umami'].track();
  }, []);
  return (
    <Row justify="center">
      <Col xs={21}>
        <Typography>
          <Title>Refunds</Title>
          <Paragraph>You can ask for a refund if you started a subscription but decided later the service wasn't a match for you.</Paragraph>
          <Paragraph>
            Go to <strong>Login Menu &gt; Settings &gt; Manage Subscription</strong> and you can ask Stripe for a refund
            <strong> if you made the purchase less than 7 days before</strong>. You will be refunded in full.
          </Paragraph>
          <Paragraph>This policy does not apply for volume purchases or other custom deals.</Paragraph>
        </Typography>
      </Col>
    </Row>
  );
}

export default Refunds;
