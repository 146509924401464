import React, { useContext, useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import { ChannelEvents } from '../types';
import VerticalProgress, { adjustBounce } from './VerticalProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBiohazard, faWater } from '@fortawesome/free-solid-svg-icons';
import { ChannelContext } from '../connections/ChannelProvider';

function ProgressCancer() {
  const { channel, events$ } = useContext(ChannelContext);
  const [info, setInfo] = useState<ChannelEvents>(new ChannelEvents());
  const [sub, setSub] = useState<Subscription>(null);
  const [cancer, setCancer] = useState(0);
  const [flood, setFlood] = useState(0);

  useEffect(() => {
    if (sub) sub.unsubscribe();
    if (events$) {
      setSub(
        events$.subscribe((event) => {
          setInfo((prev) => {
            adjustBounce(prev, event, 'cancer', setCancer);
            adjustBounce(prev, event, 'flood', setFlood);
            return event;
          });
        }),
      );
    }
    return () => {
      if (sub) sub.unsubscribe();
      setInfo(new ChannelEvents());
      setSub(null);
    };
  }, [channel, events$]);

  useEffect(() => {
    if (cancer !== 0) setTimeout(() => setCancer(0), 3000);
    if (flood !== 0) setTimeout(() => setFlood(0), 3000);
  }, [flood, cancer]);

  return (
    <VerticalProgress
      progress={[
        {
          title: 'Cancer',
          color: 'darkGreen',
          bubbles: true,
          waves: '0,100,0',
          dark: false,
          pct: Math.max(0, info.cancer || 0),
          icon: <FontAwesomeIcon icon={faBiohazard} size={'xl'} bounce={cancer === 1} fade={cancer === -1} />,
        },
        {
          title: 'Drowned',
          bubbles: false,
          color: '#0838A1',
          waves: '8,56,161',
          dark: false,
          pct: Math.max(0, info.drops || 0),
          icon: <FontAwesomeIcon icon={faWater} size={'xl'} bounce={flood === 1} fade={flood === -1} />,
        },
      ]}
    />
  );
}

export default ProgressCancer;
