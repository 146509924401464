import React, { useContext, useEffect, useState } from 'react';
import { ChatMessage, MessageConnection } from '../types';
import { Typography, Timeline, Skeleton } from 'antd';
import './Messages.css';
import { ChannelContext } from '../connections/ChannelProvider';
import { Subscription } from 'rxjs';
import { AriaLiveContext } from '../connections/AriaLiveProvider';

const { Text, Paragraph } = Typography;

function Messages({ messages$, original = false, deslang = false, msgsSize = 11, sizeCss = 'medium', ariaLive = true }: MessageConnection) {
  const { channel } = useContext(ChannelContext);
  const { ariaRead } = useContext(AriaLiveContext);
  const [messages, setMessages] = useState([]);
  const [sub, setSub] = useState<Subscription>(null);

  function parseMessage(msg: ChatMessage) {
    if (!msg || msg.text === '' || msg.deslang === '') return null;
    return `entry: ${msg.deslang}`;
  }

  useEffect(() => {
    if (sub) sub.unsubscribe();
    if (messages$) {
      setSub(
        messages$.subscribe((message) => {
          if (messages.some((m) => m.id === message.id)) return; // ignore duplicated messages
          if (ariaLive) {
            const read = parseMessage(message);
            if (read) ariaRead(read);
          }
          setMessages((prev) => {
            if (prev.length > msgsSize) return [...prev.slice(1, 100), message];
            else return [...prev, message];
          });
        }),
      );
    }
    return () => {
      if (sub) sub.unsubscribe();
      setMessages([]);
      setSub(null);
    };
  }, [channel, messages$]);

  return channel && channel !== '' ? (
    <Skeleton loading={!messages || messages.length === 0} active paragraph={{ rows: 3 }}>
      <Timeline
        className={`messages message-${sizeCss}`}
        items={messages.map((message: ChatMessage) => ({
          key: message.id,
          color: message.color,
          children: (
            <div className={'message ' + (message.highlight ? 'highlight' : '')}>
              <Text strong>{message.name}:</Text>
              &nbsp;
              <Text>{original ? message.rawText : deslang ? message.deslang : message.text}</Text>
            </div>
          ),
        }))}
      ></Timeline>
    </Skeleton>
  ) : (
    <Paragraph>&nbsp;</Paragraph>
  );
}

export default Messages;
